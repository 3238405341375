<template>
  <div id="app">
    <div class="container">
      <div class="home">
        <div class="header">
          <logo :pictureSrc="logoSrc"/>
          <div class="colorMenu">
            <eye :pictureSrc="eyeSrc"/>
            <color-switch @click.native="changeColors"/>
          </div>
        </div>
        <div class="greeting">
          <div class="hi">Hi intern!</div>
          <div class="welcome">Welcome to MSI 2021 Front-end test</div>
          <div class="start">Lets start using The Dogs API</div>
        </div>
        <div class="navMenu">
          <nav-block title='voting' pictureBg='#B4B7FF' picureSrc='vote-table.png' routerSrc='voting'/>
          <nav-block title='breeds' pictureBg='#97EAB9' picureSrc='pet-breeds.png' routerSrc='breeds'/>
          <nav-block title='gallery' pictureBg='#FFD280' picureSrc='images-search.png' routerSrc='gallery'/>
        </div>
      </div>
      <div class="main">
          <router-view/>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
#app {
  display: flex;
  justify-content: center;
  .container {
    display: flex;
    width: 1263px;
    margin-top: 30px;
    margin-left: 117px;
    .home{
      position: fixed;
      width: 446px;
      .header {
        display: flex;
        .colorMenu {
          display: flex;
          margin-top: 1px;
          margin-left: auto;
          gap: 5px;
        }
      }
      .greeting {
        margin-top: 79px;
        font-family: $font;
        color: var(--black);
        .hi {
          font-size: 44px;
          font-weight: 500;
          line-height: 58px;
        }
        .welcome {
          color: $greyFont;
          margin-top: 10px;
          font-size: 20px;
          font-weight: 400;
          line-height: 29px;
        }
        .start {
          margin-top: 60px;
          font-size: 20px;
          font-weight: 500;
          line-height: 29px;
        }
      }
      .navMenu {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        justify-content: center;

        margin-top: 20px;
      }
    }
    .main {
      width: 680px;
      border-radius: 20px;
      margin-left: 583px;
    }
  }
}
</style>

<script>
import {mapActions} from 'vuex'
import navBlock from './components/navBlock.vue'
import Logo from './components/logo.vue'
import ColorSwitch from './components/colorSwitch.vue'
import Eye from './components/eye.vue'

export default {
  components: { navBlock, Logo, ColorSwitch, Eye },
  name: 'home',
  methods: {
    ...mapActions([
      'GET_BREEDS'
    ]),
    changeColors() {
      let root = document.querySelector(':root')

      if (this.dark_mode === false) {
        root.style.setProperty('--girlBg', 'rgba(255, 255, 255, 0.05)');
        root.style.setProperty('--bodyBg', 'rgba(29, 29, 29, 1)');
        root.style.setProperty('--black', 'rgba(255, 255, 255, 1)');
        root.style.setProperty('--white', 'rgba(255, 255, 255, 0.05)');
        root.style.setProperty('--hover', 'rgba(255, 134, 142, 0.2)');
        root.style.setProperty('--eye', 'rgba(255, 255, 255, 0.05)');
        root.style.setProperty('--searchBtn', 'rgba(255, 134, 142, 0.2)');
        root.style.setProperty('--grey', 'rgba(140, 140, 140, 0.05)');
        root.style.setProperty('--shadow', 'rgba(255, 134, 142, 0.2)');
        root.style.setProperty('--galleryBlockInfo', 'rgba(40, 40, 40, 1)');
        root.style.setProperty('--breedSelector', 'rgba(255, 255, 255, 0.05)');
        root.style.setProperty('--gallerySelector', 'rgba(29, 29, 29, 1)');
        root.style.setProperty('--pinkFont', 'rgba(255, 255, 255, 1)');
        this.logoSrc = 'LogoDark.png'
        this.eyeSrc = 'eyeDark.png'
        this.dark_mode = true
      } else {
        root.style.setProperty('--girlBg', 'rgba(251, 224, 220, 1)');
        root.style.setProperty('--bodyBg', 'rgba(248, 248, 247, 1)');
        root.style.setProperty('--black', 'rgba(0, 0, 0, 1)');
        root.style.setProperty('--white', 'rgba(255, 255, 255, 1)');
        root.style.setProperty('--hover', 'rgba(251, 224, 220, 1)');
        root.style.setProperty('--eye', 'rgba(255, 255, 255, 1)');
        root.style.setProperty('--searchBtn', 'rgba(251, 224, 220, 1)');
        root.style.setProperty('--grey', 'rgba(248, 248, 247, 1)');
        root.style.setProperty('--shadow', 'rgba(251, 224, 220, 1)');
        root.style.setProperty('--galleryBlockInfo', 'rgba(255, 255, 255, 1)');
        root.style.setProperty('--breedSelector', 'rgba(248, 248, 247, 1)');
        root.style.setProperty('--gallerySelector', 'rgba(255, 255, 255, 1)');
        root.style.setProperty('--pinkFont', 'rgba(255, 134, 142, 1)');
        this.logoSrc = 'Logo.png'
        this.eyeSrc = 'eye.png'
        this.dark_mode = false
      }
    }
  },
  data() {
    return {
      dark_mode : false,
      logoSrc: 'Logo.png',
      eyeSrc: 'eye.png'
    }
  },
  mounted() {
    this.GET_BREEDS()
  }
}
</script>