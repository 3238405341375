<template>
    <div class="uploadLink">
        <div class="pic"></div>
        <div class="title">{{ title }}</div>
    </div>
</template>

<style scoped lang=scss>
    .uploadLink {
        display: flex;
        text-align: center;
        justify-content: center;
        align-items: center;
        width: 143px;
        height: 40px;
        margin-left: auto;
        border-radius: 10px;
        color: $FontColor;
        background-color: var(--searchBtn);
        font-family: $font;
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 2px;
        text-transform: uppercase;
        user-select: none;
        .pic {
            width: 16px;
            height: 16px;
            margin-right: 10px;
            background-image: url("~@/assets/img/upload-16.png");
        }
        &:hover {
            color: $btnBg;
            background-color: $FontColor;
            .pic {
                background-image: url("~@/assets/img/upload-white-16.png");
            }
        }
    }
</style>

<script>
    export default {
        name: 'upload-link',
        props: {
            title: {
                type: String,
                default: 'upload'
            }
        },
        data() {
            return {
                
            }
        },
    }
</script>