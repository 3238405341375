<template>
    <div>
        <ul class="galleryGrid" :style="{ 'grid-template-rows': `repeat(${this.limits / 5 * 3}, 140px)` }">
            <gallery-block 
                v-for="(breed, index) in paginatedBreeds"
                :key="breed.name"
                :breed_data="breed"
                :type="type"
                :class="`image image${index + 1}`"
                @imageClicked="imageClicked"
            />
        </ul>
        <div class="pagination">
            <div 
                v-for="page in pages"
                :key="page.id"
                @click="pageClicked(page)"
                class="pages"
                :class="{'selected' : page === pageNumber}"
                > {{page}}</div>
        </div>
    </div>
</template>

<style scoped lang=scss>
    .galleryGrid {
        display: grid;
        list-style: none;
        grid-template-columns: repeat(3, 200px);
        gap: 20px;
        margin: 0 20px 0 20px;
        li {
            border-radius: 20px;
        }
        .image {
            background-position: center;
            background-size: cover;
        }
        .image2 {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 1;
            grid-row-end: 3;
        }
        .image5 {
            grid-column-start: 2;
            grid-column-end: 4;
            grid-row-start: 2;
            grid-row-end: 4;
        }
        .image7 {
            grid-column-start: 3;
            grid-column-end: 4;
            grid-row-start: 4;
            grid-row-end: 6;
        }
        .image9 {
            grid-column-start: 1;
            grid-column-end: 3;
            grid-row-start: 5;
            grid-row-end: 7;
        }
         .image12 {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 7;
            grid-row-end: 9;
        }
        .image15 {
            grid-column-start: 2;
            grid-column-end: 4;
            grid-row-start: 8;
            grid-row-end: 10;
        }
        .image17 {
            grid-column-start: 1;
            grid-column-end: 2;
            grid-row-start: 10;
            grid-row-end: 12;
        }
       .image19 {
            grid-column-start: 2;
            grid-column-end: 4;
            grid-row-start: 11;
            grid-row-end: 13;
        }
    }
    .pagination {
        display: flex;
        margin: 20px 20px 0 20px;
        flex-wrap: wrap;
        justify-content: center;
        gap: 5px;
        .pages {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 20px;
            height: 20px;
            border-radius: 5px;
            color: $FontColor;
            font-family: $font;
            font-size: 14px;
            background-color: var(--searchBtn);
            cursor: pointer;
            &:hover {
                background-color: $FontColor;
                color: $btnBg;
            }
        }
        .selected {
            background-color: $FontColor;
            color: $btnBg;
        }
    }
</style>

<script>
import GalleryBlock from './galleryBlock.vue'
import {mapGetters, mapActions} from  'vuex'
    export default {
        name: 'gallery-grid',
        components: {
            GalleryBlock
        },
        computed: {
            ...mapGetters([
                'BREEDS',
                'GALLERY_IMAGES',
                'NAMES'
            ]),
            pages() {
                let type = this.type
                if(type == 'gallery') {
                    return Math.ceil(this.GALLERY_IMAGES.length / Number(this.limits));
                } else {
                    return Math.ceil(this.BREEDS.length / Number(this.limits));
                }
            },
            paginatedBreeds() {
                let a = this.sortByBreed()
                let type = this.type
                let start = (this.pageNumber - 1) * Number(this.limits);
                let end = start + Number(this.limits);
                if(type == 'gallery') {
                    return this.GALLERY_IMAGES.slice(start, end)
                } else {           
                    return a.slice(start, end)
                }
            },
        },
        props: {
            type: {
                type: String,
                default: ''
            },
            breed_data: {
                type: Object,
                default() {
                    return {}
                }
            },
            limits: {
                type: String,
                default:'10'
            },
            breeds: {
                type: String,
                default:''
            },
        },
        methods: {
            ...mapActions([
                'GET_NAME',
                'GET_BREEDS'
            ]),
            pageClicked(page) {
                this.pageNumber = page;
            },
            imageClicked(id) {
                this.$router.push( {name: 'info', query: { 'id': id }  })
            },
            sortByBreed() {
                let breeds = this.BREEDS
                breeds = this.BREEDS.filter(item => {
                    return item.name.toLowerCase().includes(this.NAMES.toLowerCase());
                });
                return breeds
            }
        },
        watch: {
            limits() {
                this.pageNumber = 1
            },
            breeds() {
                this.GET_NAME(this.breeds)
                this.pageNumber = 1
            }
        },
        data() {
            return {
                pageNumber: 1
            }
        },
    }
</script>