<template>
    <div class="btn back" @click="$router.go(-1)"></div>
</template>

<style scoped lang=scss>
    .back {
        background-image: url("~@/assets/img/back-20.png");
        background-color: var(--searchBtn);
        &:hover {
            background-image: url("~@/assets/img/back-white-20.png");
        }
    }
</style>

<script>
    export default {
        name: 'back-btn',
        data() {
            return {
                
            }
        },
    }
</script>