import Vue from 'vue'
import VueRouter from 'vue-router'
import girl from '../views/girl.vue'
import voting from '../views/voting.vue'
import breeds from '../views/breeds.vue'
import gallery from '../views/gallery.vue'
import info from '../views/info.vue'
import search from '../views/search.vue'
import likes from '../views/likes.vue'
import dislikes from '../views/dislikes.vue'
import favorites from '../views/favorites.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'girl',
    component: girl
  },
  {
    path: '/voting',
    name: 'voting',
    component: voting
  },
  {
    path: '/breeds',
    name: 'breeds',
    component: breeds
  },
  {
    path: '/info',
    name: 'info',
    component: info,
    props: true,
  },
  {
    path: '/gallery',
    name: 'gallery',
    component: gallery
  },
  {
    path: '/search',
    name: 'search',
    component: search
  },
  {
    path: '/likes',
    name: 'likes',
    component: likes
  },
  {
    path: '/dislikes',
    name: 'dislikes',
    component: dislikes
  },
  {
    path: '/favorites',
    name: 'favorites',
    component: favorites
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
