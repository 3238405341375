<template>
    <li class="galleryBlock" @click="imageClicked">
        <div v-if="type == ''" :style="{ 'background-image': `url(${this.breed_data.image.url})` }" class="image">
            <div class="bg">
                <div class="title">
                    {{breed_data.name}}
                </div>
            </div>
        </div>
        <div v-else-if="type == 'gallery'" :style="{ 'background-image': `url(${this.breed_data.url})` }" class="image">
            <div class="bg galleryBg">
                <favorite-btn/>
                <like-btn class="unFav"/>
            </div>
        </div>
    </li>
</template>

<style scoped lang=scss>
    .galleryBlock {
        width: 100%;
        height: 100%;
        .image {
            display: block;
            width: 100%;
            height: 100%;
            border-radius: 20px;
            background-position: center;
            background-size: cover;
            cursor: pointer;
            .bg {
                display: none;
                justify-content: center;
                align-items: flex-end;
                width: 100%;
                height: 100%;
                border-radius: 20px;
                background-color: rgba(255, 134, 142, 0.6);
                .title {
                    font-family: $font;
                    text-align: center;
                    margin: 0 10px 10px 10px;
                    border-radius: 10px;
                    background-color: var(--galleryBlockInfo);
                    color: $FontColor;
                    padding: 5px 42px;
                }
            }
            .galleryBg {
                align-items: center;
                .unFav {
                    display: none;
                }

            }
            &:hover {
                .bg {
                    display: flex;
                }
            }
        }
    }
</style>

<script>
import FavoriteBtn from './favoriteBtn.vue'
import LikeBtn from './likeBtn.vue'

    export default {
        components: { FavoriteBtn, LikeBtn },
        name: 'gallery-block',
        props: {
            type: {
                type: String,
                default: ''
            },
            routerSrc: {
                type: String,
                default: 'breeds'
            },
            breed_data: {
                type: Object,
                default() {
                    return {}
                }
            }
        },
        methods: {
            imageClicked() {
                if(this.type == 'gallery') {
                    return
                } else {
                    this.$emit('imageClicked', this.breed_data.id)
                }              
            }
        },
        data() {
            return {

            }
        },
    }
</script>