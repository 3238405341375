<template>
  <div class="girl">
    <img :src="require(`../assets/img/girl-and-pet.png`)" alt="Girl and dog">
  </div>
</template>

<style lang="scss">
    .girl {
      background-color: var(--girlBg);
      border-radius: 20px;
      height: 840px;
        img {
            margin-left: -65px;
            margin-top: -92px;
            user-select: none;
        }
    }
</style>

<script>

export default {
  components: {  },
  name: 'girl',
}
</script>

