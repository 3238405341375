<template>
    <div class="eye">
        <img :src="require(`@/assets/img/${pictureSrc}`)" alt="">
    </div>
</template>

<style scoped lang=scss>
    .eye{
        width: 24px;
        height: 24px;
        border-radius: 50px;
        background-color: var(--eye);
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
    }
</style>

<script>
    export default {
        name: 'eye',
        props: {
            pictureSrc: {
                type: String,
                default: 'eye.png'
            }
        },
        data() {
            return {
                
            }
        },
    }
</script>