<template>
  <div>
      <div class="header">
        <search-form/>
        <voting-links/>
      </div>
      <div class="galleryMain">
          <div class="nav">
            <back-btn/>
            <nav-btn title='gallery'/>
            <upload-link/>
          </div>
          <form action="" class="galleryForm">
            <div class="selectorContainer">
              <gallery-selector v-model="form.order"/>
              <gallery-selector title='type' v-model="form.type"/>
            </div>
            <div class="selectorContainer">
              <gallery-selector title='breed' v-model="form.breed_id"/>
              <div class="limit">
                <gallery-selector title='limit' width='240px' v-model="limit"/>
                <update-btn @click.native="updateGallery"/>
              </div>
            </div>
          </form>
          <gallery-grid 
            :limits="limit"
            type='gallery'/>
          <!-- <img v-for="image in this.GALLERY_IMAGES" :key="image.url" :src="image.url" alt=""> -->
      </div>
  </div>
</template>

<style lang="scss" scoped>
    .header {
        display: flex;
        gap: 10px;
    }
    .galleryMain {
        display: flex;
        flex-wrap: wrap;
        background-color: var(--white);
        border-radius: 20px;
        margin-top: 10px;
        padding-bottom: 20px;
        .nav {
            display: flex;
            gap: 10px;
            width: 100%;
            margin: 20px;
        }
        .galleryForm {
          display: flex;
          flex-direction: column;
          flex-wrap: wrap;
          background-color: var(--breedSelector);
          width: 100%;
          margin: 0 20px 20px 20px;
          padding-bottom: 20px;
          border-radius: 20px;
          .selectorContainer {
            display: flex;
            gap: 20px;
            margin: 10px 20px 0 20px;
            .limit {
              display: flex;
              align-items: flex-end;
              gap: 10px;
            }
          }
        }
    }
</style>

<script>
import BackBtn from '../components/backBtn.vue'
import GallerySelector from '../components/gallerySelector.vue'
import NavBtn from '../components/navBtn.vue'
import SearchForm from '../components/searchForm.vue'
import UploadLink from '../components/uploadLink.vue'
import VotingLinks from '../components/votingLinks.vue'
import UpdateBtn from '../components/updateBtn.vue'
import galleryGrid from '../components/galleryGrid.vue'
import {mapActions} from 'vuex'

export default {
  name: 'gallery',
  components: {
    VotingLinks,
    SearchForm,
    BackBtn,
    NavBtn,
    UploadLink,
    GallerySelector,
    UpdateBtn,
    galleryGrid
  },
  methods: {
    ...mapActions([
      'GET_IMAGES'
    ]),
    updateGallery() {
      this.GET_IMAGES(this.form)
    }
  },
  computed: {

  },
  data() {
    return {
      form: {
        order: '',
        type: 'jpg,png',
        breed_id: '',
      },
      limit: '5',
    }
  },
  mounted() {
    this.GET_IMAGES(this.form)
  }
}
</script>
