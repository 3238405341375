<template>
    <a href="/" class="logo">
        <img :src="require(`@/assets/img/${pictureSrc}`)" alt="">
    </a>
</template>

<style scoped lang=scss>
    .logo{
        display: flex;
        width: 106px;
        height: 24px;
    }
</style>

<script>
    export default {
        name: 'logo',
        props: {
            pictureSrc: {
                type: String,
                default: 'Logo.png'
            }
        },
        data() {
            return {
                
            }
        },
    }
</script>