<template>
    <div class="votingLinks">
        <router-link to='likes' class="likes"></router-link>
        <router-link to=favorites class="favorites"></router-link>
        <router-link to=dislikes class="dislikes"></router-link>
    </div>
</template>

<style scoped lang=scss>
    .votingLinks {
        display: flex;
        gap: 10px;
        a {
            width: 60px;
            height: 60px;
            background-color: var(--white);
            background-repeat: no-repeat;
            background-position: center;
            border-radius: 20px;
            cursor: pointer;
            &:hover {
                background-color: var(--hover);
            };
            &.router-link-active {
                background-color: $FontColor;
            }
        }
        .likes {
            background-image: url("~@/assets/img/like-30.png");
            &.router-link-active {
                background-image: url("~@/assets/img/like-white-30.png");
            }
        }
        .favorites {
            background-image: url("~@/assets/img/fav-30.png");
            &.router-link-active {
                background-image: url("~@/assets/img/fav-white-30.png");
            }
        }
        .dislikes {
            background-image: url("~@/assets/img/dislike-30.png");
            &.router-link-active {
                background-image: url("~@/assets/img/dislike-white-30.png");
            }
        }
    }
</style>

<script>
    export default {
        name: 'voting-links',
        data() {
            return {
                
            }
        },
    }
</script>
