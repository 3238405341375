<template>
    <div class="searchForm">
        <input type="text" 
               placeholder="Search for breeds by name" 
               class="input" 
               v-model="searchValue"> 
               <search-btn 
               class="btn"
               @click.native="search(searchValue)"/>
    </div>
</template>

<style scoped lang=scss>
    .searchForm {
        display: flex;
        width: 100%;
        height: 60px;
        background-color: var(--white);
        border-radius: 20px;
        justify-content: space-between;
        align-items: center;
        .input{
            width: 380px;
            height: 30px;
            color: #8C8C8C;
            margin-left: 20px;
            font-size: 20px;
            font-family: $font;
        }
        .btn {
            margin-right: 10px;
        }
        &:hover {
            box-shadow: inset 0 0 0 2px var(--shadow);
        }
        &:focus-within {
            box-shadow: inset 0 0 0 2px rgba(255, 134, 142, 1);
        }
    }
    
</style>

<script>
import SearchBtn from '../components/searchBtn.vue'
import {mapActions, mapGetters} from 'vuex'

    export default {
        components: { SearchBtn },
        name: 'search-form',
        computed: {
            ...mapGetters([
               'SEARCH_VALUE' 
            ]),
        },
        methods: {
            ...mapActions([
               'GET_SEARCH_VALUE' 
            ]),
            search(value) {
                this.GET_SEARCH_VALUE(value);
                this.$router.push({name: 'search', query: { 'breed': value, }})
            }
        },
        data() {
            return {
               searchValue: '' 
            }
        },
    }
</script>