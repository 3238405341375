<template>
    <div class="breedsSelector">
        <!-- <select :name=id :id=name>
            <option v-for="breed in this.$store.state.breeds" :key="breed.name" :value="breed.name">{{title}}{{breed.name}}</option>
        </select> -->
        <!-- <select :name=id :id=name>
            <option v-for="breed in BREEDS" :key="breed" :value="breed.name">{{title}}{{breed.name}}</option>
        </select> -->
        <select v-if="type == 'limits'" v-model="inputVal" name="limit" id="limit">
            <option value="5">Limit: 5</option>
            <option value="10">Limit: 10</option>
            <option value="15">Limit: 15</option>
            <option value="20">Limit: 20</option>
        </select>
        <select v-else v-model="inputVal" name="breeds" id="breeds">
            <option value="">All breeds</option>
            <option v-for="breed in this.$store.state.breeds" :key="breed.name" :value="breed.name">{{breed.name}}</option>
        </select>
    </div>
</template>

<style scoped lang=scss>
    .breedsSelector {
        select {
            width: 100%;
            height: 40px;
            padding-left: 10px;
            font-family: $font;
            font-size: 16px;
            color: $greyFont;
            background-color: var(--breedSelector);
            background-image: url("~@/assets/img/dropdown-12.png");
            background-repeat: no-repeat;
            background-position: right;
            background-position: calc(100% - 10px);
            border-radius: 10px;
            appearance: none;
            cursor: pointer;
            &:hover {
                box-shadow: inset 0 0 0 2px var(--shadow);
            }
        }
    }
</style>

<script>
import {mapGetters} from 'vuex'

    export default {
        name: 'breeds-selector',
        props: {
            type: {
                type: String,
                default: 'breeds'
            },
            title: {
                type: String,
                default: ''
            },
            value: {
                type: String,
                required: true
           },
        },
        computed: {
            ...mapGetters(['BREEDS', 'LIMITS', 'NAMES']),
            inputVal: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('input', val);
                },
            }
        },
        data() {
            return {
                
            }
        },
    }
</script>