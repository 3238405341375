<template>
  <div class="voting">
      <div class="header">
        <search-form/>
        <voting-links/>
      </div>
      <div class="votingMain">
          <div class="nav">
            <back-btn/>
            <nav-btn/>
          </div>
          <div class="images" :style="{ 'background-image': `url(${this.RANDOM_IMAGE[0].url})` }">
            <vote-menu class="menu"/>
          </div>
          <ul class="actionLog">
            <li v-for="vote in VOTES" :key="vote.image_id" class="actionInfo">
              <div class="time">
                {{vote.created_at.slice(11, 16)}}
              </div>
              <p class="info">
                Image ID: <span>{{vote.image_id}}</span> was added to Favourites
              </p>
            </li>
          </ul>
      </div>
  </div>
</template>

<style lang="scss" scoped>
    .voting {
        .header {
            display: flex;
            gap: 10px;
        }
        .votingMain {
            display: flex;
            flex-direction: column;
            background-color: var(--white);
            border-radius: 20px;
            margin-top: 10px;
            padding-bottom: 20px;
            .nav {
                display: flex;
                gap: 10px;
                margin: 20px;
            }
            .images {
              display: flex;
              justify-content: center;
              align-items: flex-end;
              padding-top: 88%;
              width: 640px;
              border-radius: 20px;
              margin: 0 20px 0 20px;
              background-position: center;
              background-size: cover;
              .menu {
                margin-bottom: -38px;
              }
            }
            .actionLog {
              display: flex;
              flex-direction: column;
              height: 400px;
              overflow-y: scroll;
              gap: 10px;
              margin-top: 52px;
              font-family: $font;
              .actionInfo {
                display: flex;
                align-items: center;
                margin: 0 20px 0 20px;
                padding: 15px;
                border-radius: 10px;
                background-color: var(--breedSelector);
                background-image: url("~@/assets/img/like-color-20.png");
                background-repeat: no-repeat;
                background-position: center;
                background-position-x: calc(100% - 20px);
                .time {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  width: 61px;
                  height: 30px;
                  border-radius: 5px;
                  color: var(--black);
                  background-color: var(--gallerySelector);
                }
                .info {
                  color: $greyFont;
                  margin-left: 20px;
                  span {
                    font-weight: 500;
                    color: var(--black);
                  }
                }
              }
            }
        }
    }
</style>

<script>
import BackBtn from '../components/backBtn.vue'
import NavBtn from '../components/navBtn.vue'
import SearchForm from '../components/searchForm.vue'
import VoteMenu from '../components/voteMenu.vue'
import VotingLinks from '../components/votingLinks.vue'
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'voting',
  components: {
    VotingLinks,
    SearchForm,
    BackBtn,
    NavBtn,
    VoteMenu
  },
  computed: {
    ...mapGetters([
      'VOTES',
      'RANDOM_IMAGE'
    ]),
  },
  methods: {
    ...mapActions([
      'GET_VOTES',
      'GET_RANDOM_IMAGE'
    ]),
  },
  mounted() {
    this.GET_VOTES()
    this.GET_RANDOM_IMAGE()
  }
}
</script>
