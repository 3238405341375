<template>
    <div class="colorSwitch" @click="switchClicked()">
        <div class="switcher" :class="{'selected' : dark === true}"></div>
    </div>
</template>

<style scoped lang=scss>
    .colorSwitch{
        width: 44px;
        height: 24px;
        border-radius: 50px;
        background-color: var(--hover);
        cursor: pointer;
        .switcher {
            width: 16px;
            height: 16px;
            border-radius: 40px;
            margin: 4px;
            margin-left: 24px;
            background-color: $FontColor;
            transition-duration: 0.5s;
        }
        .selected {
            margin-left: 4px;
        }
    }
</style>

<script>
    export default {
        name: 'color-switch',
        methods: {
            switchClicked() {
                if(this.dark == false) {
                    this.dark = true
                } else {
                    this.dark = false
                }
            },
        },
        data() {
            return {
                dark: false,
            }
        },
    }
</script>