<template>
    <div class="noItems">
        <p>No item found</p>
    </div>
</template>

<style scoped lang=scss>
    .noItems {
        display: flex;
        margin: 0 20px 0 20px;
        padding: 18px 20px 18px 20px;
        background-color: var(--grey);
        border-radius: 10px;
        font-family: $font;
        font-size: 16px;
        color: #8C8C8C;
    }
</style>

<script>
    export default {
        name: 'no-items',
        data() {
            return {
                
            }
        },
    }
</script>