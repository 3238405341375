<template>
    <div class="btn update"></div>
</template>

<style scoped lang=scss>
    .update {
        background-image: url("~@/assets/img/update-20.png");
        background-color: var(--gallerySelector);
        &:hover {
            background-image: url("~@/assets/img/update-white-20.png");
        }
    }
</style>

<script>
    export default {
        name: 'update-btn',
        data() {
            return {
                
            }
        },
    }
</script>