<template>
    <input type="submit" value="" class="sort desc">
</template>

<style scoped lang=scss>
    .desc {
        background-image: url("~@/assets/img/soft-revert-20.png");
        &:hover {
            background-image: url("~@/assets/img/sort-revert-color-20.png");
            box-shadow: inset 0 0 0 2px var(--shadow);
        }
    }
</style>

<script>
    export default {
        name: 'desc-btn',
        data() {
            return {
                
            }
        },
    }
</script>