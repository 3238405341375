<template>
    <input type="submit" value="" class="btn search">
</template>

<style scoped lang=scss>
    .search {
        background-image: url("~@/assets/img/search-20.png");
        background-color: var(--searchBtn);
        &:hover {
            background-image: url("~@/assets/img/search-white-20.png");
        }
    }
</style>

<script>
    export default {
        name: 'search-btn',
        data() {
            return {
                
            }
        },
    }
</script>