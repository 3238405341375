<template>
  <div>
      <div class="header">
        <search-form/>
        <voting-links/>
      </div>
      <div class="votingMain">
          <div class="nav">
            <back-btn/>
            <nav-btn title="breeds"/>
            <breeds-selector v-model="breeds" class="breeds"/>
            <breeds-selector v-model="limits" type=limits class="limit"/>
            <asc-btn @click.native="ascSort"/>
            <desc-btn @click.native="descSort"/>
          </div>
          <div class="gallery">
            <gallery-grid 
              :limits="limits"
              :breeds="breeds"
              />
          </div>
      </div>
  </div>
</template>

<style lang="scss" scoped>
    .header {
        display: flex;
        gap: 10px;
    }
    .votingMain {
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        border-radius: 20px;
        margin-top: 10px;
        padding-bottom: 20px;
        .nav {
            display: flex;
            gap: 10px;
            margin: 20px;
            .breeds {
              width: 226px;
            }
            .limit {
              width: 102px;
            }
        }
    }
</style>

<script>
import BackBtn from '../components/backBtn.vue'
import NavBtn from '../components/navBtn.vue'
import SearchForm from '../components/searchForm.vue'
import VotingLinks from '../components/votingLinks.vue'
import BreedsSelector from '../components/breedsSelector.vue'
import AscBtn from '../components/ascBtn.vue'
import DescBtn from '../components/descBtn.vue'
import galleryGrid from '../components/galleryGrid.vue'
import {mapGetters, mapActions} from  'vuex'

export default {
  name: 'breeds',
  components: {
    VotingLinks,
    SearchForm,
    BackBtn,
    NavBtn,
    BreedsSelector,
    AscBtn,
    DescBtn,
    galleryGrid
  },
  computed: {
    ...mapGetters([
      'BREEDS'
    ]),
  },
  methods: {
    ...mapActions([
      'GET_BREEDS'
    ]),
    ascSort() {
      this.BREEDS.sort((a,b) => a.name.localeCompare(b.name))
    },
    descSort() {
      this.BREEDS.reverse((a,b) => a.name.localeCompare(b.name))
    },
  },
  data() {
    return {
      limits: '10',
      breeds: ''
    }
  },
  mounted() {
    this.GET_BREEDS()
  }
}
</script>
