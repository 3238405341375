<template>
    <div class="btn like"></div>
</template>

<style scoped lang=scss>
    .like {
        background-image: url("~@/assets/img/fav-color-20.png");
        background-color: $btnBg;
        &:hover {
            background-image: url("~@/assets/img/fav-full-white-20.png");
        }
    }
</style>

<script>
    export default {
        name: 'like-btn',
        data() {
            return {
                
            }
        },
    }
</script>