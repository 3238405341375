<template>
    <router-link :to=title class="navBtn">{{title}}</router-link>
</template>

<style scoped lang=scss>
    .navBtn {
        display: flex;
        align-items: center;
        height: 39px;
        padding: 1px 28px 0px 31px;
        border-radius: 10px;
        background-color: $FontColor;
        color: $btnBg;
        font-family: $font;
        font-size: 20px;
        line-height: 30px;
        letter-spacing: 2.5px;
        text-transform: uppercase;
        &:hover {
            color: $FontColor;
            background-color: var(--hover);
        };
    }
</style>

<script>
    export default {
        name: 'nav-btn',
        props: {
            title: {
                type: String,
                default: 'voting'
            },
        },
        data() {
            return {
                
            }
        },
    }
</script>