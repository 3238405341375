<template>
    <div class="gallerySelector" :style="{ 'width': `${this.width}` }">
        <label class="title">{{ title }}</label>
        <select v-if="title == 'order'" v-model="inputVal" name="" id="order">
            <option value="">Random</option>
            <option value="DESC">Desc</option>
            <option value="ASC">Asc</option>
        </select>
        <select v-else-if="title == 'type'" v-model="inputVal" name="" id="type">
            <option value="">All</option>
            <option value="gif">Animated</option>
            <option value="jpg,png">Static</option>
        </select>
        <select v-else-if="title == 'breed'" v-model="inputVal" name="" id="breed">
            <option value="">None</option>
            <option v-for="breed in this.$store.state.breeds" :key="breed.name" :value="breed.id">{{breed.name}}</option>
        </select>
        <select v-else-if="title == 'limit'" v-model="inputVal" name="" id="limit">
            <option value="5">5 items per page</option>
            <option value="10">10 items per page</option>
            <option value="15">15 items per page</option>
            <option value="20">20 items per page</option>
        </select>
    </div>
</template>

<style scoped lang=scss>
    .gallerySelector {
        display: flex;
        flex-direction: column;
        text-align: left;
        width: 290px;
        height: 58px;
        .title {
            margin-left: 10px;
            font-family: $font;
            font-size: 10px;
            letter-spacing: 0.3px;
            text-transform: uppercase;
            line-height: 18px;
            color: #8C8C8C;
        }
        select {
            height: 40px;
            padding-left: 10px;
            font-family: $font;
            font-size: 16px;
            color: var(--black);
            background-color: var(--gallerySelector);
            background-image: url("~@/assets/img/dropdown-12.png");
            background-repeat: no-repeat;
            background-position: right;
            background-position-x: calc(100% - 10px);
            border-radius: 10px;
            appearance: none;
            cursor: pointer;
            &:hover {
                box-shadow: inset 0 0 0 2px var(--shadow);
            }
        }
    }
</style>

<script>

    export default {
        name: 'gallery-selector',
        props: {
            title: {
                type: String,
                default: 'order'
            },
            width: {
                type: String,
                default: '290px'
            },
            value: {
                type: String,
                required: true
           },
        },
        computed: {
            inputVal: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('input', val);
                },
            }
        },
        data() {
            return {
                
            }
        },
    }
</script>