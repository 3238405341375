<template>
  <div>
      <div class="header">
        <search-form/>
        <voting-links/>
      </div>
      <div class="votingMain">
          <div class="nav">
            <back-btn/>
            <nav-btn title="breeds"/>
          </div>
          <div class="gallery">
              <gallery-grid 
              :limits="limits"
              :breeds="breeds"
              />
          </div>
      </div>
  </div>
</template>

<style lang="scss" scoped>
    .header {
        display: flex;
        gap: 10px;
    }
    .votingMain {
        display: flex;
        flex-direction: column;
        background-color: var(--white);
        border-radius: 20px;
        margin-top: 10px;
        padding-bottom: 20px;
        .nav {
            display: flex;
            gap: 10px;
            margin: 20px;
        }
    }
</style>

<script>
import BackBtn from '../components/backBtn.vue'
import NavBtn from '../components/navBtn.vue'
import SearchForm from '../components/searchForm.vue'
import VotingLinks from '../components/votingLinks.vue'
import galleryGrid from '../components/galleryGrid.vue'
// import {mapGetters} from  'vuex'

export default {
  name: 'search',
  components: {
    VotingLinks,
    SearchForm,
    BackBtn,
    NavBtn,
    galleryGrid
  },
  computed: {
    breeds() {
        return this.$route.query.breed
    },
  },
  methods: {

  },
  data() {
    return {

    }
  },
}
</script>
