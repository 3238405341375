<template>
    <router-link :to=routerSrc class="navBlock">
        <div class="picture" :style="{ 'background-color': pictureBg }">
            <img :src="require(`@/assets/img/${picureSrc}`)">
        </div>
        <div class="button">
            {{ title }}
        </div>
    </router-link>
</template>

<style scoped lang=scss>
    .navBlock {
        text-decoration: none;
        .picture {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 130px;
            height: 190px;
            border: $pictureBorder;
            border-radius: 20px;
            cursor: pointer;
            user-select: none;
        }       
        .button {
            width: 136px;
            height: 35px;
            margin-top: 10px;
            padding-left: 2px;
            padding-top: 1px;
            line-height: 35px;
            background-color: var(--white);
            color: $FontColor;
            border-radius: 10px;
            cursor: pointer;
            font-family: $font;
            font-weight: 500;
            font-size: 12px;
            text-align: center;
            letter-spacing: 2px;
            text-transform: uppercase;
            user-select: none;
        }
        &:hover {
            .button {
                background-color: var(--hover);
            }
            .picture {
                border: $pictureBorderHover;
            }
        };
        &.router-link-active {
            .button {
                background-color: $FontColor;
                color: $btnBg;
            }
            .picture {
                border: $pictureBorderActive;
            }
        };
    }

</style>

<script>
    export default {
        name: 'nav-block',
        props: {
            title: {
                type: String,
                default: 'voting'
            },
            pictureBg: {
                type: String,
                default: '#B4B7FF'
            },
            picureSrc: {
                type: String,
                default: 'vote-table.png'
            },
            routerSrc: {
                type: String,
                default: 'voting'
            }
        },
        data() {
            return {
                
            }
        },
    }
</script>
