import Vue from 'vue'
import Vuex from 'vuex'
import axiosInstance from '@/api/index.js';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    name: '',
    breeds: [{
      height: {
        metric: ''
      },
      width: {
        metric: ''
      },
      image: {
        url: ''
      }
    }],
    images: [],
    gallery_images: [],
    searchValue: '',
    votes: [],
    random_image: [],
    votes_images: [],
  },
  mutations: {
    SET_BREEDS: (state, breeds) => {
      state.breeds = breeds;
    },
    SET_IMAGES: (state, images) => {
      state.images = images;
    },
    SET_GALLERY_IMAGES: (state, images) => {
      state.gallery_images = images;
    },
    SET_SEARCH_VALUE: (state, value) => {
      state.searchValue = value
    },
    SET_NAME: (state, value) => {
      state.name = value
    },
    SET_VOTES: (state,images) => {
      state.votes = images
    },
    SET_RANDOM_IMAGE: (state, random_image) => {
      state.random_image = random_image
    },
    SET_VOTES_IMAGES: (state, image) => {
      state.votes_images = image
    }
  },
  actions: {
    GET_BREEDS({commit}) {
      return axiosInstance('breeds', {
        method: 'GET',
      })
        .then((breeds) => {
          commit('SET_BREEDS', breeds.data);
          return breeds.data;
        })
        .catch((error) => {
          console.log(error)
          return error
        })
    },
    GET_IMAGES_BY_ID({commit}, id) {
      return axiosInstance('images/search', {
        method: 'GET',
        params: {
            limit: 5,
            breed_id: id,
            size: "small"
        }
      })
      .then((images) => {
        commit('SET_IMAGES', images.data);
        return images.data;
      })
      .catch((error) => {
        console.log(error)
        return error
      })
    },
    GET_RANDOM_IMAGE({commit}) {
      return axiosInstance('images/search', {
        method: 'GET',
        params: {
            size: "small"
        }
      })
      .then((images) => {
        commit('SET_RANDOM_IMAGE', images.data);
        return images.data;
      })
      .catch((error) => {
        console.log(error)
        return error
      })
    },
    POST_LIKE(id) {
      return axiosInstance('votes', {
        method: 'POST',
        headers: {
          ['x-api-key'] : "60dd465d-4e37-4b3f-b218-dab97f1a3282",
        },
        data: {
          image_id: id,
          value: 1
        },
        responseType: 'json'
      })
    },
    GET_IMAGES({commit}, {order, type, breed_id}) {
      return axiosInstance('images/search', {
        method: 'GET',
        params: {
            limit: 100,
            breed_id: breed_id,
            mime_types: type,
            order: order
        }
      })
      .then((images) => {
        commit('SET_GALLERY_IMAGES', images.data);
        return images;
      })
      .catch((error) => {
        console.log(error)
        return error
      })
    },
    GET_VOTES({commit}) {
      return axiosInstance('votes', {
        method: 'GET',
        headers: {
          ['x-api-key'] : "60dd465d-4e37-4b3f-b218-dab97f1a3282",
        },
      })
      .then((images) => {
        commit('SET_VOTES', images.data);
        return images;
      })
      .catch((error) => {
        console.log(error)
        return error
      })
    },
    GET_VOTES_IMAGES({commit}, breed_id) {
      return axiosInstance('images', {
        method: 'GET',
        headers: {
          ['x-api-key'] : "60dd465d-4e37-4b3f-b218-dab97f1a3282",
        },
        params: {
          image_id: breed_id,
      },
      })
      .then((images) => {
        commit('SET_VOTES_IMAGES', images.data);
        return images;
      })
      .catch((error) => {
        console.log(error)
        return error
      })
    },
    GET_SEARCH_VALUE({commit}, value) {
      commit('SET_SEARCH_VALUE', value)
    },
    GET_NAME({commit}, value) {
      commit('SET_NAME', value)
    }
  },
  getters: {
    BREEDS(state) {
      return state.breeds;
    },
    IMAGES(state) {
      return state.images;
    },
    GALLERY_IMAGES(state) {
      return state.gallery_images;
    },
    SEARCH_VALUE(state) {
      return state.searchValue;
    },
    NAMES(state) {
      return state.name
    },
    VOTES(state) {
      return state.votes
    },
    RANDOM_IMAGE(state) {
      return state.random_image
    },
    VOTES_IMAGES(state) {
      return state.votes_images
    }
  }
})
