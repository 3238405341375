<template>
    <div class="votingMenu">
        <div class="voteMenuBtn like" @click="postLike()"></div>
        <div class="voteMenuBtn favorite" @click="postToFavorites()"></div>
        <div class="voteMenuBtn dislike" @click="postDislike()"></div>
    </div>
</template>

<style scoped lang=scss>
    .votingMenu {
        display: flex;
        gap: 4px;
        width: 248px;
        height: 80px;
        background-color: var(--galleryBlockInfo);
        box-shadow: 0 0 0 4px var(--galleryBlockInfo);
        border-radius: 20px;
        cursor: pointer;
        .voteMenuBtn {
            width: 80px;
            height: 80px;
            background-repeat: no-repeat;
            background-position: center;
        }
        .like {
            border-radius: 20px 0px 0px 20px;
            background-color: rgba(151, 234, 185, 1);
            background-image: url("~@/assets/img/like-white-30.png");
            &:hover {
                background-color: rgba(151, 234, 185, 0.3);
                background-image: url("~@/assets/img/like-color-30.png");
            }
        }
        .favorite {
            background-color: rgba(255, 134, 142, 1);
            background-image: url("~@/assets/img/fav-white-30.png");
            &:hover {
                background-color: rgba(255, 134, 142, 0.3);
                background-image: url("~@/assets/img/fav-30.png");
            }
            &:active {
                background-image: url("~@/assets/img/fav-full-white-30.png");
                background-color: rgba(255, 134, 142, 1);
            }
        }
        .dislike {
            border-radius: 0px 20px 20px 0px;
            background-color: rgba(255, 210, 128, 1);
            background-image: url("~@/assets/img/dislike-white-30.png");
            &:hover {
                background-color: rgba(255, 210, 128, 0.3);
                background-image: url("~@/assets/img/dislike-color-30.png");
            }
        }
    }
</style>

<script>
import {mapActions, mapGetters} from 'vuex'
import axiosInstance from '@/api/index.js'

    export default {
        name: 'voting-menu',
        data() {
            return {
                
            }
        },
        computed: {
            ...mapGetters([
            'RANDOM_IMAGE'
            ]),
        },
        methods: {
            ...mapActions([
            'GET_RANDOM_IMAGE',
            'POST_LIKE',
            'GET_VOTES',
            'GET_VOTES_IMAGES',
            ]),
            postLike() {
                axiosInstance.post('https://api.thedogapi.com/v1/votes', {image_id : this.RANDOM_IMAGE[0].id, value: 1})
                this.GET_RANDOM_IMAGE()
                // this.GET_VOTES()
                this.GET_VOTES_IMAGES(this.RANDOM_IMAGE[0].id)
            },
            postDislike() {
                axiosInstance.post('https://api.thedogapi.com/v1/votes', {image_id : this.RANDOM_IMAGE[0].id, value: 0})
                this.GET_RANDOM_IMAGE()
                // this.GET_VOTES()
                // this.GET_VOTES_IMAGES()
            },
            postToFavorites() {
                axiosInstance.post('https://api.thedogapi.com/v1/favourites', {image_id : this.RANDOM_IMAGE[0].id})
                this.GET_RANDOM_IMAGE()
                // this.GET_VOTES()
                // this.GET_VOTES_IMAGES()
            },

        },
    }
</script>