<template>
  <div class="info">
      <div class="header">
        <search-form/>
        <voting-links/>
      </div>
      <div class="infoMain">
          <div class="nav">
            <back-btn/>
            <nav-btn class="idBtn breeds" title='breeds'/>
            <div class="idBtn">{{breed.id}}</div>
          </div>
          <!-- <div class="images" :style="{ 'background-image': `url(${$store.state.images[2].url})` }"></div> -->
          <div class="images">
              <div class="wrapper" :style="{ 'margin-left': `-${100 * currentImage}%`}">
                  <img v-for="image in IMAGES" :key="image.url" :src="image.url" alt="dog" class="pic">
              </div>
          </div>
          <div class="pagination">
                <div class="pagesBg">
                    <div v-for="(page, index) in IMAGES"
                         :key="page.url"
                         @click="pageClicked(index)"
                         class="pages" 
                         :class="{'selected' : page === currentImage}"></div>
                </div>
          </div>
          <div class="breedName"><p>{{breed.name}}</p></div>
          <div class="breedInfo">
              <div class="breedFor">{{breed.bred_for}}</div>
              <div class="breedData">
                  <div class="breedTemp">
                    <p><span>Temperament:</span></p>
                    <p>{{breed.temperament}}</p>                    
                  </div>
                  <div class="breedPhis">
                      <p><span>Height:</span> {{breed.height.metric}} cm at the withers</p>
                      <p><span>Weight:</span> {{breed.weight.metric}} kgs</p>
                      <p><span>Life span:</span> {{breed.life_span}}</p>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<style lang="scss">
    .info {
        .header {
            display: flex;
            gap: 10px;
        }
        .infoMain {
            display: flex;
            flex-direction: column;
            text-align: center;
            background-color: var(--white);
            border-radius: 20px;
            margin-top: 10px;
            padding-bottom: 75px;
            .nav {
                display: flex;
                gap: 10px;
                margin: 20px;
                .idBtn {
                    display: flex;
                    align-items: center;
                    height: 39px;
                    padding: 1px 29px 0px 31px;
                    border-radius: 10px;
                    background-color: $FontColor;
                    color: $btnBg;
                    font-family: $font;
                    font-size: 20px;
                    line-height: 30px;
                    letter-spacing: 2.5px;
                    text-transform: uppercase;
                }
                .breeds {
                    background-color: var(--hover);
                    color: $FontColor;
                    &:hover {
                        background-color: $FontColor;
                        color: $btnBg;
                    }
                }
            }
            .images {
                display: flex;
                flex: 0 0 100%;
                height: 360px;
                width: 640px;
                border-radius: 20px;
                margin: 0 20px 0 20px;
                overflow: hidden;
                .wrapper{
                    display: flex;
                    flex: 0 0 100%;
                    padding-left: 0px;
                    justify-items: center;
                    transition-duration: 0.4s;
                    .pic {
                        object-fit: cover;
                        height: 100%;
                        width: 100%;
                    }
                }
            }
            .pagination {
                display: flex;
                margin: -15px 20px 0 20px;
                justify-content: center;
                .pagesBg {
                    display: flex;
                    padding: 10px;
                    gap: 5px;
                    border-radius: 20px;
                    background-color: var(--galleryBlockInfo);
                    .selected {
                        background-color: $FontColor;
                    }
                    .pages {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 10px;
                        height: 10px;
                        border-radius: 10px;
                        color: $FontColor;
                        font-family: $font;
                        font-size: 14px;
                        background-color: var(--searchBtn);
                        cursor: pointer;
                        &:hover {
                            background-color: $FontColor;
                            color: $btnBg;
                        }
                    }
                }
            }
            .breedName {
                display: flex;
                justify-content: center;
                margin-top: 20px;
                z-index: 1;
                p {
                    background-color: var(--galleryBlockInfo);
                    color: var(--black);
                    border-radius: 20px;
                    font-family: $font;
                    font-size: 36px;
                    font-weight: 500;
                    padding: 5px 40px 5px 40px;    
                }
            }
            .breedInfo {
                display: flex;
                flex-direction: column;
                justify-content: center;
                font-family: $font;
                background-color: var(--galleryBlockInfo);
                border: 2px solid var(--shadow);
                border-radius: 20px;
                margin: -32px 20px 0 20px;
                padding-bottom: 38px;
                transition-duration: 0.25s;
                .breedFor {
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 29px;
                    color: $greyFont;
                    margin-top: 24px;
                }
                .breedData {
                    display: flex;
                    gap: 21px;
                    margin: 20px 40px 0 38px;
                    color: $greyFont;
                    span {
                        color: var(--black);
                        font-weight: 500;
                    }
                    .breedTemp {
                        flex-basis: 50%;
                        text-align: left;
                        font-size: 16px;
                        line-height: 23px;
                    }
                    .breedPhis {
                        display: flex;
                        flex-direction: column;
                        gap: 10px;
                        flex-basis: 50%;
                        text-align: left;
                    }
                }
            }
        }            
    }
</style>

<script>
import BackBtn from '../components/backBtn.vue'
import NavBtn from '../components/navBtn.vue'
import SearchForm from '../components/searchForm.vue'
import VotingLinks from '../components/votingLinks.vue'
import {mapGetters, mapActions} from 'vuex'

export default {
    name: 'info',
    components: {
        VotingLinks,
        SearchForm,
        BackBtn,
        NavBtn
    },
    computed: {
        ...mapGetters([
            'BREEDS',
            'IMAGES'
        ]),
        pageId() {
            return this.$route.query.id
        },
        breed() {
            let result = {}
            let vm = this;
            this.BREEDS.map(function (breed){
                if (breed.id == vm.$route.query.id) {
                    result = breed;
                } 
            })
            return result;
        },
    },
    props: {

    },
    methods: {
        ...mapActions([
        'GET_IMAGES_BY_ID'
        ]),
        pageClicked(page) {
            this.currentImage = page;
        },
    },
    watch: {
        pageId() {
            this.GET_IMAGES_BY_ID(this.pageId)
            console.log('ailala')
        }
    },
    data() {
        return {
            currentImage: 0
        }
    },

    mounted() {
        this.GET_IMAGES_BY_ID(this.pageId)
    }  
}
</script>